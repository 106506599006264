<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.candidates") }}
              </div>
              <div class="block-sarche">
                <div class="header__search mobile__margintop d-flex">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="8" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              class="mobile__margintop"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'candidates.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          :class="mode ? 'table__myday' : 'table__mynight'"
          class="table-my-code table-bordered"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.id.show">
                {{ $t("message.photo") }}
              </th>
              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>
              <th v-if="columns.last_name.show">
                {{ columns.last_name.title }}
              </th>
              <th v-if="columns.middle_name.show">
                {{ columns.middle_name.title }}
              </th>
              <th v-if="columns.date_of_birth.show">
                {{ columns.date_of_birth.title }}
              </th>

              <th v-if="columns.gender_id.show">
                {{ columns.gender_id.title }}
              </th>

              <th v-if="columns.vacancy_ids.show">
                {{ columns.vacancy_ids.title }}
              </th>

              <th v-if="columns.nation_id.show">
                {{ columns.nation_id.title }}
              </th>
              <th v-if="columns.social_id.show">
                {{ columns.social_id.title }}
              </th>
              <th v-if="columns.phone_number.show">
                {{ columns.phone_number.title }}
              </th>

              <th v-if="columns.email.show">
                {{ columns.email.title }}
              </th>
              <th v-if="columns.status_id.show">
                {{ columns.status_id.title }}
              </th>
              <th v-if="columns.min_wage.show">
                {{ columns.min_wage.title }}
              </th>
              <th v-if="columns.max_wage.show">
                {{ columns.max_wage.title }}
              </th>
              <th>
                {{ $t("Резюме") }}
              </th>
              <th>
                {{ $t("message.point") }}
              </th>
              <th>
                {{ $t("message.accept") }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>
              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.id.show"></th>
              <th v-if="columns.name.show">
                <crm-input
                  v-model="filterForm.name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.name.title"
                ></crm-input>
              </th>

              <th v-if="columns.last_name.show">
                <crm-input
                  v-model="filterForm.last_name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.last_name.title"
                ></crm-input>
              </th>

              <th v-if="columns.middle_name.show">
                <crm-input
                  v-model="filterForm.middle_name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.middle_name.title"
                ></crm-input>
              </th>
              <th v-if="columns.date_of_birth.show">
                <crm-date-picker
                  v-model="filterForm.date_of_birth"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.date_of_birth.title"
                ></crm-date-picker>
              </th>

              <th v-if="columns.gender_id.show">
                <select-gender
                  v-model="filterForm.gender_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.gender_id.title"
                ></select-gender>
              </th>

              <th v-if="columns.vacancy_ids.show">
                <select-vacancy
                  v-model="filterForm.vacancy_ids"
                  :multiple="true"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-vacancy>
              </th>

              <th v-if="columns.nation_id.show">
                <select-nation
                  v-model="filterForm.nation_id"
                  :placeholder="columns.nation_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-nation>
              </th>
              <th v-if="columns.social_id.show">
                <select-social
                  v-model="filterForm.social_id"
                  :placeholder="columns.social_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-social>
              </th>
              <th v-if="columns.phone_number.show">
                <crm-input
                  v-model="filterForm.phone_number"
                  :placeholder="columns.phone_number.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></crm-input>
              </th>
              <th v-if="columns.email.show">
                <crm-input
                  v-model="filterForm.email"
                  :placeholder="columns.email.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></crm-input>
              </th>

              <th v-if="columns.status_id.show">
                <select-status
                  :table_name="'candidates'"
                  v-model="filterForm.status_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.status_id.title"
                ></select-status>
              </th>
              <th v-if="columns.min_wage.show">
                <crm-input
                  v-model="filterForm.min_wage"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.min_wage.title"
                ></crm-input>
              </th>
              <th v-if="columns.max_wage.show">
                <crm-input
                  v-model="filterForm.max_wage"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.max_wage.title"
                ></crm-input>
              </th>
              <th></th>
              <th></th>
              <th>
                <crm-input
                  :disabled="true"
                  v-model="filterForm.max_wage"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="$t('message.accept')"
                ></crm-input>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  v-model="filterForm.created_at"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.created_at.title"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  v-model="filterForm.updated_at"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.updated_at.title"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="(candidate, index) in list"
              :key="'candidate' + index"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ candidate.id }}</td>
              <td v-if="columns.id.show">
                <div class="image__preview application_img">
                  <el-image
                    v-if="candidate && candidate.image && candidate.image.path"
                    class="candidate-avatar"
                    style="width: 50px; height: 50px"
                    :src="
                      candidate.image.path
                        ? baseUrl + candidate.image.path
                        : 'img/avatar.jpeg'
                    "
                    :preview-src-list="[
                      candidate.image.path
                        ? baseUrl + candidate.image.path
                        : 'img/avatar.jpeg'
                    ]"
                  >
                  </el-image>
                </div>
              </td>

              <td v-if="columns.name.show">
                {{ candidate.name }}
              </td>
              <td v-if="columns.last_name.show">
                {{ candidate.last_name }}
              </td>
              <td v-if="columns.middle_name.show">
                {{ candidate.middle_name }}
              </td>
              <td v-if="columns.date_of_birth.show">
                {{ candidate.date_of_birth }}
              </td>
              <td v-if="columns.gender_id.show">
                {{ candidate.gender ? candidate.gender.name : null }}
              </td>
              <td v-if="columns.vacancy_ids.show && candidate.vacancies">
                <div
                  v-for="(vacancy, vacancyIndex) in candidate.vacancies"
                  :key="'vacancy' + vacancyIndex"
                >
                  {{ vacancy.name }}
                </div>
              </td>
              <td v-if="columns.nation_id.show">
                {{ candidate.nation ? candidate.nation.nationality : null }}
              </td>
              <td v-if="columns.social_id.show">
                {{ candidate.social ? candidate.social.name : null }}
              </td>
              <td v-if="columns.phone_number.show">
                {{ candidate.phone_number }}
              </td>
              <td v-if="columns.email.show">
                {{ candidate.email }}
              </td>
              <td v-if="columns.status_id.show">
                {{ candidate.status ? candidate.status.name : null }}
              </td>
              <td v-if="columns.min_wage.show">
                {{ candidate.min_wage }}
              </td>
              <td v-if="columns.max_wage.show">
                {{ candidate.max_wage }}
              </td>
              <td v-if="columns.max_wage.show">
                <a
                  v-if="candidate && candidate.resume && candidate.resume.path"
                  href="#"
                  @click="
                    downloadFiles(candidate.id, candidate.resume.file_name)
                  "
                  >Резюме</a
                >
              </td>
              <td v-if="columns.max_wage.show">
                <a
                  v-if="candidate && candidate.point"
                  href="#"
                  @click="showInfoList(candidate.id)"
                  >{{ candidate.point }}</a
                >
              </td>
              <td v-if="columns.max_wage.show">
                <el-button
                  size="mini"
                  type="primary"
                  @click="addStaff(candidate.id)"
                  class="copy-link"
                >
                  {{ $t("message.accept") }}
                </el-button>
              </td>
              <td v-if="columns.created_at.show">
                {{ candidate.created_at }}
              </td>
              <td v-if="columns.updated_at.show">
                {{ candidate.updated_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :model="candidate"
                  :actions="actions"
                  :permissionShow="'candidates.update'"
                  :permissionDestroy="'candidates.destroy'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          :wrapperClosable="false"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :wrapperClosable="false"
          :visible.sync="drawerCreateStaff"
          size="95%"
          ref="drawerCreateStaff"
          @opened="drawerOpened('drawerCreateStaffChild')"
          @closed="drawerClosed('drawerCreateStaffChild')"
        >
          <crm-staff-create-new
            :candidate_id="selectedCandidate"
            ref="drawerCreateStaffChild"
            drawer="drawerCreateStaff"
          ></crm-staff-create-new>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          :wrapperClosable="false"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
        <el-drawer
          title="I'm outer Drawer"
          :visible.sync="showInfoModal"
          size="40%"
          :with-header="false"
          ref="show-info"
          @opened="drawerOpened('show-info')"
        >
          <div>
            <show-info
              :candidate="selectedCandidate"
              @closeModal="closeModal"
              :child="child"
              ref="show-info"
            ></show-info>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import list from "@/utils/mixins/list";
import { mapGetters, mapActions, mapState } from "vuex";
import Pagination from "@/components/pagination";
import CrmStaffCreateNew from "./components/crm-staff-create-new";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import AddTrial from "../staff/components/crm-create.vue";
import selectGender from "@/components/filters/inventory/select-gender";
import selectStatus from "@/components/filters/inventory/select-status";
import selectNation from "@/components/filters/inventory/select-nation";
import selectVacancy from "@/components/filters/inventory/select-vacancy";
import showInfo from "./components/show-info.vue";

export default {
  name: "CandidateController",
  mixins: [list],
  components: {
    CrmCreate,
    Pagination,
    AddTrial,
    CrmUpdate,
    CrmStaffCreateNew,
    selectGender,
    selectStatus,
    selectNation,
    selectVacancy,
    showInfo
  },

  data() {
    return {
      drawerCreateStaff: false,
      selectedCandidate: null,
      showInfoModal: false,
      child: {},
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      )
    };
  },
  computed: {
    ...mapGetters({
      list: "candidate/list",
      columns: "candidate/columns",
      pagination: "candidate/pagination",
      statues: "candidate/statues",
      filter: "candidate/filter",
      sort: "candidate/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "candidate/index",
      setPagination: "candidate/setPagination",
      updateSort: "candidate/updateSort",
      updateFilter: "candidate/updateFilter",
      updateColumn: "candidate/updateColumn",
      downloadFile: "candidate/downloadFile",
      updatePagination: "candidate/updatePagination",
      show: "candidate/show",
      empty: "candidate/empty",
      delete: "candidate/destroy",
      refreshData: "candidate/refreshData"
    }),

    addStaff(id) {
      this.selectedCandidate = id;
      this.drawerCreateStaff = true;
    },
    downloadFiles(id, name) {
      var data = { id: id };
      return this.downloadFile(data).then(response => {
        let blob = new Blob([response.data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        console.log(link);
      });
    },
    showInfoList(candidate_id) {
      this.showInfoModal = true;
      this.child.candidate_id = candidate_id;
    },
    closeModal(val) {
      this.showInfoModal = val;
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    }
  }
};
</script>
